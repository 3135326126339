import { Nullable } from 'shared_DEPRECATED/types';
import { Attachment } from 'shared_DEPRECATED/types/Attachment';
import { ObjectValues, LinkPreview, ProcessingStatus } from 'types/common';

import { CHAT_MESSAGE_TYPES } from 'features/chat/config';
import { ParentMessageNullable } from 'features/chat/config/types/ParentMessage';

import { UnuploadedAttachment } from './Attachment';
import { Reaction } from './Reaction';
import { ChatMember } from './types';

export const enum EVENT_TYPE {
  PONG = 'pong',
  CHAT_MESSAGE_RECEIVED_BY_SERVER = 'chat_message_received_by_server',
  CHAT_MESSAGE_CREATED = 'chat_message_created',
  CHAT_MESSAGE_EDITED = 'chat_message_edited',
  CHAT_MESSAGE_READ_UPDATE = 'chat_message_read_update',
  CHAT_MEMBERS_UPDATE = 'chat_members_update',
  CHAT_MEMBER_ONLINE_STATUS_UPDATE = 'chat_member_online_status_update',
  CHAT_MESSAGE_REACTION_ADDED_BY_INTERLOCUTOR = 'chat_message_reaction_added',
  CHAT_MESSAGE_REACTION_REMOVED_BY_INTERLOCUTOR = 'chat_message_reaction_removed',
  CHAT_MESSAGE_REACTION_ADDED = 'chat_message_reaction_add_received_by_server',
  CHAT_MESSAGE_REACTION_REMOVED = 'chat_message_reaction_remove_received_by_server',
  CHAT_UNREAD_MESSAGES_COUNT_UPDATED = 'chat_unread_messages_count_updated',
  CHAT_MESSAGE_ATTACHMENT_PROCESSED = 'chat_message_attachment_processed',
}

export const enum OUTGOING_EVENT_TYPE {
  CHAT_MESSAGE_CREATE = 'chat_message_create',
  CHAT_MESSAGE_SEEN_BY_CLIENT = 'chat_message_seen_by_client',
  CHAT_GET_MEMBERS = 'chat_get_members',
  PING = 'ping',
  CHAT_MESSAGE_REACTION_ADD = 'chat_message_reaction_add',
  CHAT_MESSAGE_REACTION_REMOVE = 'chat_message_reaction_remove',
}

export const enum MESSAGE_STATUS {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  SENT = 'SENT',
  READ = 'READ',
  SCHEDULED = 'SCHEDULED',
}

export type UUID = string;

type EmitMessage = {
  event: OUTGOING_EVENT_TYPE.CHAT_MESSAGE_CREATE;
  chat_id: Nullable<string>;
  text: string;
  reply_to: Nullable<string>;
  temporary_message_id: UUID;
  fromUserId: UUID;
  metadata?: {
    [key: string]: string;
  };
  linkPreview: Nullable<LinkPreview>;
};

type EmitMessageWithAttachments = {
  event: OUTGOING_EVENT_TYPE.CHAT_MESSAGE_CREATE;
  chat_id: Nullable<string>;
  text: string;
  reply_to: Nullable<string>;
  temporary_message_id: UUID;
  attachments: Attachment[];
  metadata?: {
    [key: string]: string;
  };
};

export type ChatMessageType = ObjectValues<typeof CHAT_MESSAGE_TYPES>;

export type DeletedMessageContent = {
  type: typeof CHAT_MESSAGE_TYPES.DELETED;
  text: 'The message was deleted';
};

export type CoachingSessionRecapMessageContent = {
  type: typeof CHAT_MESSAGE_TYPES.COACHING_SESSION_RECAP;
  coachingSessionId: string;
  subject: string;
  content: string;
  attachments: Attachment[];
  submittedAt: string;
};

export type PlainMessageContent = {
  type: typeof CHAT_MESSAGE_TYPES.PLAIN;
  text: string;
  attachments: Attachment[];
  linkPreview: Nullable<LinkPreview>;
};

export type SystemMessageContent = {
  type: typeof CHAT_MESSAGE_TYPES.SYSTEM;
  text: string;
};

export type MessageContentBasedOnType =
  | DeletedMessageContent
  | CoachingSessionRecapMessageContent
  | PlainMessageContent
  | SystemMessageContent;

export type BaseMessage = {
  event: OUTGOING_EVENT_TYPE | EVENT_TYPE;
  chatId: Nullable<string>;
  type: ChatMessageType;
  content: MessageContentBasedOnType;
  messageId: UUID;
  text: string;
  replyTo: Nullable<string>;
  replyToMessage: ParentMessageNullable;
  createdAt: string;
  editedAt?: string;
  status: MESSAGE_STATUS;
  reactions: {
    [key: string]: Reaction[];
  };
  attachments?: UnuploadedAttachment[] | Attachment[];
  temporaryMessageId?: UUID;
  fromUserId: string;
  scheduledMessageId?: UUID;
  scheduledFor?: string;
  deleted: boolean;
};

type SentMessage = BaseMessage & {
  event: OUTGOING_EVENT_TYPE.CHAT_MESSAGE_CREATE;
  temporaryMessageId: UUID;
  status: MESSAGE_STATUS.CREATED;
  editedAt?: string;
  reactions: {
    [key: string]: Reaction[];
  };
};

export type MessageReceivedByServer = BaseMessage & {
  event: EVENT_TYPE.CHAT_MESSAGE_RECEIVED_BY_SERVER;
  temporaryMessageId: UUID;
  status: MESSAGE_STATUS.SENT;
  reactions: {
    [key: string]: Reaction[];
  };
  linkPreview: LinkPreview | null;
};

export type MessageCreated = BaseMessage & {
  event: EVENT_TYPE.CHAT_MESSAGE_CREATED;
};

export type MessageNotification = {
  event: EVENT_TYPE.CHAT_MESSAGE_READ_UPDATE;
  chatId: Nullable<string>;
  messageId: UUID;
};

type OutgoingNotification = {
  event: OUTGOING_EVENT_TYPE.CHAT_MESSAGE_SEEN_BY_CLIENT;
  chat_id: Nullable<string>;
  message_id: UUID;
};

type PingNotification = {
  event: OUTGOING_EVENT_TYPE.PING;
};

type PongNotification = {
  event: EVENT_TYPE.PONG;
};

type GetMembersNotification = {
  event: OUTGOING_EVENT_TYPE.CHAT_GET_MEMBERS;
  chatId: Nullable<string>;
};

type MembersUpdateNotification = {
  event: EVENT_TYPE.CHAT_MEMBERS_UPDATE;
  members: ChatMember[];
};

type MemberOnlineUpdateNotification = {
  event: EVENT_TYPE.CHAT_MEMBER_ONLINE_STATUS_UPDATE;
  userEmail: string;
  online: boolean;
};

export type OutgoingMessageReaction = {
  event:
    | OUTGOING_EVENT_TYPE.CHAT_MESSAGE_REACTION_ADD
    | OUTGOING_EVENT_TYPE.CHAT_MESSAGE_REACTION_REMOVE;
  chatId: Nullable<string>;
  messageId: UUID;
  reaction: string;
};

export type IncomingMessageReaction = {
  event:
    | EVENT_TYPE.CHAT_MESSAGE_REACTION_ADDED
    | EVENT_TYPE.CHAT_MESSAGE_REACTION_REMOVED
    | EVENT_TYPE.CHAT_MESSAGE_REACTION_ADDED_BY_INTERLOCUTOR
    | EVENT_TYPE.CHAT_MESSAGE_REACTION_REMOVED_BY_INTERLOCUTOR;
  chatId: Nullable<string>;
  messageId: UUID;
  reaction: string;
  userId: string;
};

export type MessageAttachmentProcessed = {
  event: EVENT_TYPE.CHAT_MESSAGE_ATTACHMENT_PROCESSED;
  chatId: Nullable<string>;
  messageId: UUID;
  attachmentId: string;
  processingStatus: ProcessingStatus;
  isScheduledMessage: boolean;
};

export type ChatMessageEdited = BaseMessage & {
  event: EVENT_TYPE.CHAT_MESSAGE_EDITED;
};

export type OutgoingSendMessages =
  | EmitMessage
  | EmitMessageWithAttachments
  | OutgoingNotification
  | PingNotification
  | GetMembersNotification
  | OutgoingMessageReaction;

export type Message = SentMessage | MessageReceivedByServer | MessageCreated;

export type IncomingMessage =
  | Message
  | MessageNotification
  | PongNotification
  | MembersUpdateNotification
  | MemberOnlineUpdateNotification
  | IncomingMessageReaction
  | MessageAttachmentProcessed
  | ChatMessageEdited;

export type Messages = Message[] | BaseMessage[];
