import { VARIANTS } from 'shared_DEPRECATED/components/Button/config';

export const routeNames = {
  GOALS: 'goals',
  PLANNING: 'sprint-planning',
  DASHBOARD: 'sprints',
  CONNECTIONS: 'users',
  COACH: 'coach',
  ADMIN: 'admin',
  SESSIONS: 'sessions',
};

export const DAILY_STATUS_VALUES = {
  SUCCESS: 'success',
  FAIL: 'fail',
  EMPTY: 'empty',
  PASS: 'pass',
};

export const DAILY_STATUSES = [
  DAILY_STATUS_VALUES.SUCCESS,
  DAILY_STATUS_VALUES.FAIL,
  DAILY_STATUS_VALUES.EMPTY,
];

export const WEEK_TARGET_ABSENT = 0;

export const btnVariantIconColors = {
  critical: 'var(--white)',
  primary: 'var(--white)',
  secondary: 'var(--black)',
  tertiary: 'var(--black)',
  symbol: 'var(--black)',
} as { [key in VARIANTS]?: string };

export const API_STATUSES = {
  ERROR: 'error',
  LOADING: 'loading',
  SUCCESS: 'success',
};
